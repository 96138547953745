<template>
  <div class="wrap">
    <div class="header">
      <pageHeader :headerWhite="headerWhite"></pageHeader>
    </div>
    <div class="page1" ref="refPageOne">
      <video src="../assets/video/home_top.mp4" autoplay muted loop poster="../assets/img/home/home_top_img.jpg"></video>
    </div>
    <div class="page2 contanier">
      <h3 class="h3">睿见概况</h3>
      <div class="surveyBoxOne">
        <ul class="survey" ref="refSurveyUl">
          <li class="one">
            <div ref="refSurveySp">
              <span class="number"><img src="../assets/icon/home/80.png" alt="" /></span><span class="tit">研发团队</span>
            </div>
            <img src="../assets/icon/home/sun.png" alt="" class="rightImg" />
          </li>
          <i class="line"></i>
          <li class="two">
            <div ref="refSurveySp">
              <span class="number"><img src="../assets/icon/home/40.png" alt="" /></span><span class="tit">专业算法工程师</span>
            </div>
            <img src="../assets/icon/home/rocket.png" alt="" class="rightImg" />
          </li>
          <i class="line"></i>
          <li class="three">
            <div ref="refSurveySp">
              <span class="number"><img src="../assets/icon/home/40.png" alt="" /></span><span class="tit">保司专业运营人员</span>
            </div>
            <img src="../assets/icon/home/trophy.png" alt="" class="rightImg" />
          </li>
        </ul>
      </div>
      <div class="surveyBoxTwo">
        <div class="survey_left">
          <p>
            中科睿见致力致力于运用人工智能、医疗引擎、大数据分析、云计算、安全计算等前沿科技，为人们打造全新的数据智能化、自动化技术解决方案，通过在传统业务中植入智能算法、跨界知识图谱、智能自动化驱动等新型技术的融合，对传统业务场景进行科技赋能及流程再造。
          </p>
          <!-- <div class="learn_more"><span>了解更多</span></div> -->
          <div class="learn_more" @click="$router.push({ name: 'AboutUs' })">了解更多<span class="iconfont arrow">&#xe621;</span></div>
        </div>
        <div class="survey_right">
          <!-- <video src="../assets/video/home_ruijian_survey.mp4" controls autoplay muted loop></video> -->
          <!-- <video src="../assets/video/home_ruijian_survey.mp4" controls autoplay muted loop></video> -->
          <div id="player"></div>
        </div>
      </div>
    </div>
    <div class="page3">
      <p class="pOne h3">智能驱动 引领行业变革</p>
      <p class="pTwo">不断优化模型计算法，为客户提供最便捷优质的服务</p>
      <ul class="content_box">
        <div class="tab_title">
          <div :class="['tit', page3Index == index ? 'tab_ac' : '']" v-for="(item, index) in page3Data" :key="index" @click="page3Index = index">{{ item.title }}</div>
        </div>
        <li class="active">
          <img class="prev" src="../assets/img/home/prev.png" alt="" @click="changeTab('prev')" />
          <div class="tab_item">
            <div class="le">
              <img :src="page3Data[page3Index].img" alt="" />
            </div>
            <div class="ri">
              <span class="title">{{ page3Data[page3Index].title }}</span>
              <p class="p">{{ page3Data[page3Index].textOne }}</p>
              <div class="learn_more" v-show="page3Index <= '2'" @click="goService()">{{ page3Data[page3Index].jumpText }}<span class="iconfont arrow">&#xe621;</span></div>
            </div>
          </div>
          <img class="next" src="../assets/img/home/next.png" alt="" @click="changeTab('next')" />
        </li>
      </ul>
    </div>
    <div class="goAnalysis">
      <div class="leftB">
        <span class="tit">全析解析识别技术</span>
        <i class="lin"></i>
        <p class="txt">
          一种从表单类影像中全方位解析内容的技术解决方案。全析解析的含义不仅仅包含识别单证本身的文字，更加深拓展了对其内容的理解能力和延伸使用判断。能够从所使用行业的角度，对领域内人员提供专业辅助解析和理解作用的技术方案。
        </p>
      </div>
      <div class="rightB">
        <img src="../assets/img/home/goAnalysis_img.png" alt="" />
        <span class="learnMore" @click="$router.push({ name: 'Analysis' })">了解更多<span class="iconfont arrow">&#xe621;</span></span>
      </div>
    </div>
    <div class="page4">
      <h3 class="h3">我们的核心优势</h3>
      <ul class="my_advantage">
        <li>
          <img src="../assets/icon/home/model.png" alt="" />
          <span class="title">模式创新</span>
          <p><span>多种技术为核心</span><span>最小共性业务组件为封装单位</span><span>以业务结果交付为服务内容</span></p>
        </li>
        <li>
          <img src="../assets/icon/home/framework.png" alt="" />
          <span class="title">架构创新</span>
          <p><span>AI 业务模块+智能云+Docker</span><span>云端计算+本地数据存储</span></p>
        </li>
        <li>
          <img src="../assets/icon/home/technology.png" alt="" />
          <span class="title">技术与产业的深度融合</span>
          <p><span>洞察保险业务链</span><span>将前沿技术应用组合成智能组件</span><span>服务于业务场景深度融合</span></p>
        </li>
      </ul>
    </div>
    <div class="page5 contanier">
      <h3 class="h3">合作伙伴</h3>
      <div class="introduce">
        <p class="pOne">
          公司成立以来，已经为多家国内保险公司提供全流程智能自动化解决方案，对传统保险业务的数据提取、基于智能风险审核、风险预测、基于业务的实时大数据BI分析、精算建模、提供了端到端的解决方案。睿见科技通过不断学习行业知识、围绕行业核心价值业务输出，不断优化模型及算法，用最恰当的方案为客户打造最佳的行业解决方案。睿见科技通过不断学习行业知识、围绕行业核心价值业务输出，不断优化模型及算法，用最恰当的方案为客户打造最佳的行业解决方案。
        </p>
        <p class="pTwo">
          公司与全国占比80%以上商保业务的中国再保险集团与2020年达成全线合作，通过再保险集团的业务赋能，为整个行业输出更具行业深度的产品平台，建立基于国民健康量化模型的国家级标准化人群医疗风险分析模型。可广泛应用于政府业务、保险业务、普惠医疗等场景中。
        </p>
      </div>
      <div class="smart_box">
        <img src="../assets/img/component/logo@2x.png" alt="" />
      </div>
      <div class="my_partner">
        <ul>
          <li v-for="(item, index) in imgData" :key="index" :class="['animated', item.state === true ? 'pulse' : '']" @mouseenter="addHeadShake(index)">
            <img :src="item.path" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <pageFooter></pageFooter>
  </div>
</template>

<script>
import pageHeader from '@/components/header.vue'
import pageFooter from '@/components/footer.vue'
export default {
  name: 'Home',
  components: {
    pageHeader,
    pageFooter
  },
  data() {
    return {
      vodPlayerJs: 'https://player.polyv.net/script/player.js',
      vid: '',
      player: '',
      pageOneHeight: 0, // page1高度
      headerWhite: false, // 头部白色状态
      page2Num1: 0,
      page2Num2: 0,
      page2Num3: 0,
      scroll: 0,
      page3Index: 0,
      biggestScroll: 0, // 滚动条最大的滚动距离-控制数字的显示
      numFlag: true,
      page3Data: [
        {
          title: '智能理赔平台',
          textOne:
            '通过内置自研识别技术，对医疗险、重疾险、意外险等场景下所涉及到的医疗资料、计算资料完成自动数据采集，并集成多种知识库，对识别数据进行校正和自动核查，提高了保司在数据采集类业务上的数据完整性、准确性及效率。智能理赔平台还通过强大的规则配置引擎，可自动完成数据的自动核责、智能理算、最优理算等能力。',
          img: require('../assets/img/home/tab_item01.png'),
          jumpText: '了解更多',
          state: true
        },
        {
          title: '智能核保平台',
          textOne: '通过内置的医疗资料解读算法及核保引擎，自动根据投保客户的医疗信息给出在5大险种上的核保意见，可广泛使用于核保、预核保、销售客户筛选、产品推荐、人群分析等多种场景。',
          jumpText: '了解更多',
          img: require('../assets/img/home/tab_item02.png'),
          state: false
        },
        {
          title: '智能健康档案',
          textOne: '根据保险客户多来源非机构化&结构化数据，全自动无人工干预构建医疗信息档案，根据多维业务场景形成立体的客户风险描述，在客户风险调查、销售推荐、产品定义、客户档案等方面具有广泛应用。',
          jumpText: '了解更多',
          img: require('../assets/img/home/tab_item03.png'),
          state: false
        },
        {
          title: '医疗知识图谱',
          textOne: '构建了以器官、疾病、症状、医疗风险预测、用药、手术、合理检查指标、治疗方案为要素的医疗知识图谱，可用于保险业务中对于医学信息的查询、调查等场景。',
          jumpText: '了解更多',
          img: require('../assets/img/home/tab_item04.png'),
          state: false
        },
        {
          title: '智能双录系统',
          textOne: '提供保司在承保环节的智能双录系统，提供智能的流程提示、语言语义风险识别及自动合规审查。',
          jumpText: '了解更多',
          img: require('../assets/img/home/tab_item05.png'),
          state: false
        },
        {
          title: '智能理赔咨询机器人',
          textOne: '构建主要的保险理赔知识库，对于客户咨询的理赔为题可通过智能理赔机器人进行自动解答，并内置专属虚拟人顾问，形象友好，符合人们对于视频语音等问答形式的需求。',
          jumpText: '了解更多',
          img: require('../assets/img/home/tab_item06.png'),
          state: false
        },
        {
          title: '智能体检报告解读',
          textOne: '对于客户提交的体检报告提供快速的医疗解读，并通过专属的医疗虚拟顾问对于医疗问题进行视频解读，洞察体检报告中隐藏的异常风险，并给与复查、就医指导。',
          jumpText: '了解更多',
          img: require('../assets/img/home/tab_item07.png'),
          state: false
        }
      ],
      imgData: [
        {
          // path: require('../assets/img/home/smart.jpg'),
          path: require('../assets/img/home/CHINA_RE.jpg'),
          state: false
        },
        { path: require('../assets/img/home/Munich_RE.jpg'), state: false },
        { path: require('../assets/img/home/CHINA_LIFE.jpg'), state: false },
        { path: require('../assets/img/home/Beijing_Life.jpg'), state: false },
        { path: require('../assets/img/home/GENERALI_CHINA.jpg'), state: false },
        { path: require('../assets/img/home/Groupama_AVIC.jpg'), state: false },
        { path: require('../assets/img/home/Aixin_Life.jpg'), state: false },
        { path: require('../assets/img/home/TRUSTMUTUALLIFE.jpg'), state: false },
        { path: require('../assets/img/home/QHP.jpg'), state: false },
        { path: require('../assets/img/home/MINGYA.jpg'), state: false },
        { path: require('../assets/img/home/PICC.jpg'), state: false },
        { path: require('../assets/img/home/CHS.jpg'), state: false },
        { path: require('../assets/img/home/CHIC.jpg'), state: false },
        { path: require('../assets/img/home/CHINA_INSURANCE.jpg'), state: false },
        { path: require('../assets/img/home/Tencent.jpg'), state: false },
        { path: require('../assets/img/home/ZHXH.png'), state: false },
        { path: require('../assets/img/home/XHBX.png'), state: false },
        { path: require('../assets/img/home/SJBZ.png'), state: false },
        { path: require('../assets/img/home/YGBX.png'), state: false },
        { path: require('../assets/img/home/KLJK.png'), state: false },
        { path: require('../assets/img/home/ZYSXRS.png'), state: false }
      ]
    }
  },
  watch: {
    // 滚动条-数字增加
    scroll(val) {
      // if (val >= this.biggestScroll && this.numFlag) {
      //   this.changeNum('page2Num1', 80, 3, 50)
      //   this.changeNum('page2Num2', 60, 2, 60)
      //   this.changeNum('page2Num3', 20, 1, 80)
      //   this.numFlag = false
      // }
      // console.log('val', val)
      // console.log('pageOneHeight', this.pageOneHeight)

      // scorllTop > page1Height => 头部白色
      if (val >= this.pageOneHeight) {
        this.headerWhite = true
      } else {
        this.headerWhite = false
      }
    }
  },
  created() {
    this.vid = '226f52cfe43e42d17b482316b7c6a64f_2'
    // page1标题
    // this.changeTitle()
    window.scroll(0, 1)
    // this.biggestScroll = 930 - document.body.clientHeight
  },
  mounted() {
    this.loadPlayerScript(this.loadPlayer)
    this.pageOneHeight = this.$refs.refPageOne.clientHeight
    // console.dir(this.$refs.refPageOne.clientHeight)
    // console.dir(this.$refs)
    // console.dir(this.$refs.refSurveyUl.offsetTop)
    // console.dir(this.$refs.refSurveySp.offsetTop)

    // 监听滚动条事件
    window.addEventListener('scroll', this.scrollChange)
  },
  methods: {
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script')
        myScript.setAttribute('src', this.vodPlayerJs)
        myScript.onload = callback
        document.body.appendChild(myScript)
      } else {
        callback()
      }
    },

    loadPlayer() {
      const polyvPlayer = window.polyvPlayer
      this.player = polyvPlayer({
        wrap: '#player',
        width: '100%',
        height: '100%',
        autoplay: true,
        // skinLocation: 0,
        loop: true,
        volume: 0,
        vid: this.vid
      })
    },
    // 滚动条变化
    scrollChange() {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop
      // console.log(this.scroll)
      this.biggestScroll = this.$refs.refSurveyUl.offsetTop + this.$refs.refSurveySp.offsetTop - document.body.clientHeight
    },
    // 改变数字-从小到大    参数：需要变化的值，最大值，每次变化几个，变化间隔时间
    // changeNum(target, maxnum, step, time) {
    //   let num = this[target]
    //   this[time] = setInterval(() => {
    //     if (num >= maxnum) {
    //       this[target] = maxnum
    //       return clearInterval(this[time])
    //     }
    //     num += step
    //     this[target] = num
    //   }, time)
    // },
    // footer-鼠标进入 抖动
    addHeadShake(i) {
      this.imgData.forEach((item) => {
        item.state = false
      })
      this.imgData[i].state = true
    },
    // 了解更多-跳转到产品服务
    goService() {
      let i = 0
      // console.log(i)
      if (this.page3Index === 0) {
        i = 1
      } else if (this.page3Index === 1) {
        i = 0
      } else {
        i = this.page3Index
      }
      this.$router.push({ name: 'Service', params: { state: 1, index: i } })
    },
    changeTab(type) {
      if (type === 'prev') {
        if (this.page3Index !== 0) {
          this.page3Index -= 1
        } else {
          this.page3Index = this.page3Data.length - 1
        }
      } else {
        if (this.page3Index >= this.page3Data.length - 1) {
          this.page3Index = 0
        } else {
          this.page3Index += 1
        }
      }
    }
  },
  destroyed() {
    if (this.player) {
      this.player.destroy()
    }
    window.removeEventListener('scroll', this.scrollChange)
  }
}
</script>
<style lang="less" scoped>
#player {
  width: 100%;
  height: 100%;
}
.page1 {
  // position: relative;
  height: 720px;
  video {
    width: 100%;
    height: 100%;
  }
  // img {
  //   position: absolute;
  //   left: 50%;
  //   bottom: 30px;
  //   transform: translateX(-50%);
  //   width: 60px;
  //   height: 60px;
  // }
}
// 每页标题样式
.h3 {
  margin-top: 86px;
  height: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: #010c29;
  line-height: 40px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}
.page2 {
  height: 789px;
  overflow: hidden;
  .surveyBoxOne {
    margin-top: 60px;
    height: 103px;
    overflow: hidden;
    border-top: 1px solid #e5eaf1;
    border-bottom: 1px solid #e5eaf1;
    box-sizing: border-box;
    .survey {
      width: 105%;
      li {
        position: relative;
        display: inline-block;
        width: 305px;
        height: 101%;
        .number {
          display: block;
          margin: 16px 0 15px;
          height: 40px;
          font-size: 34px;
          // font-family: DINAlternate-Bold, DINAlternate;
          // font-weight: bold;
          // color: #010c29;
          line-height: 40px;
          img {
            width: 52px;
            height: 26px;
          }
        }
        .tit {
          // width: 56px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #676f86;
          line-height: 20px;
        }
        .rightImg {
          position: absolute;
          top: 36px;
          right: 3px;
          width: 32px;
          height: 32px;
          &:hover {
            animation: tada; /* referring directly to the animation's @keyframe declaration */
            animation-duration: 2s; /* don't forget to set a duration! */
          }
        }
      }
      .line {
        margin: 0 63px 20px;
        display: inline-block;
        width: 1px;
        height: 29px;
        background-color: #e5eaf1;
      }
    }
  }
  .surveyBoxTwo {
    margin-top: 72px;
    overflow: hidden;
    .survey_left {
      position: relative;
      float: left;
      width: 480px;
      height: 332px;
      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #010c29;
        line-height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
      }
      .learn_more {
        position: absolute;
        left: 0;
        bottom: 0;
        padding-left: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        width: 141px;
        height: 36px;
        line-height: 36px;
        color: #ffffff;
        background: #4671ff;
        box-sizing: border-box;
        cursor: pointer;
        .arrow {
          margin-left: 18px;
        }
        &:hover {
          .arrow {
            animation: arrow-move 0.8s forwards;
          }
        }
        // 箭头移动动画
        @keyframes arrow-move {
          0% {
            margin-left: 18px;
          }

          100% {
            margin-left: 25px;
          }
        }
        @-webkit-keyframes arrow-move {
          0% {
            margin-left: 18px;
          }

          100% {
            margin-left: 25px;
          }
        }
      }
    }
    .survey_right {
      float: right;
      width: 590px;
      height: 332px;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.page3 {
  width: 100%;
  height: 769px;
  background: url('../assets/img/home/homebg_page3@2x.png');
  background-size: cover;
  text-align: center;
  overflow: hidden;
  .pTwo {
    margin: 14px 0 42px;
    height: 24px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #788398;
    line-height: 24px;
  }
  .content_box {
    width: 1180px;
    margin: 0 auto;
    text-align: left;
    .tab_title {
      display: flex;
      justify-content: space-around;
      // align-items: center;
      border-bottom: 1px solid #e5eaf1;
      height: 60px;
      cursor: pointer;
      .tit {
        height: 60px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #010f36;
        line-height: 60px;
      }
    }
    .tab_ac {
      border-bottom: 2px solid #4671ff;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .prev,
      .next {
        width: 52px;
        height: 52px;
        cursor: pointer;
      }
      .tab_item {
        margin-top: 67px;
        display: flex;
        width: 900px;
        height: 366px;
        background: #ffffff;
        border-radius: 2px;
        .le {
          background-color: #4671ff;
          width: 420px;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .ri {
          position: relative;
          padding: 40px 48px;
          width: 480px;
          height: 100%;
          box-sizing: border-box;
          .title {
            width: 120px;
            height: 41px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #010f36;
            line-height: 41px;
          }
          .p {
            margin-top: 19px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #676f86;
            line-height: 28px;
          }
          .learn_more {
            position: absolute;
            bottom: 40px;
            display: inline-block;
            padding-left: 16px;
            width: 121px;
            height: 36px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 36px;
            color: #4671ff;
            border: 1px solid #4671ff;
            border-radius: 20px;
            cursor: pointer;
            box-sizing: border-box !important;
            transition: all 0.6s;
            &:hover {
              background-color: #4671ff;
              color: #fff;
              .arrow {
                animation: arrow1-move 0.4s 0.4s forwards;
              }
            }
            .arrow {
              margin-left: 14px;
            }
            // 箭头移动
            @keyframes arrow1-move {
              0% {
                margin-left: 14px;
              }

              100% {
                margin-left: 19px;
              }
            }
            @-webkit-keyframes arrow1-move {
              0% {
                margin-left: 14px;
              }

              100% {
                margin-left: 19px;
              }
            }
          }
        }
      }
    }
    // .active {
    //   position: relative;
    //   width: 672px;
    //   color: #ffffff;
    //   // .number {
    //   //   opacity: 0.1;
    //   //   color: #ffffff;
    //   // }
    //   .title {
    //     color: #ffffff;
    //     margin-top: -49px;
    //   }
    //   .pTwo {
    //     display: none;
    //   }
    //   p {
    //     width: 248px;
    //     // height: 168px;
    //     color: #ffffff;
    //     text-overflow: ellipsis;
    //     display: -webkit-box;
    //     -webkit-box-orient: vertical;
    //     -webkit-line-clamp: 6;
    //     overflow: hidden;
    //   }
    //   .pOne2 {
    //     width: 339px;
    //     height: 168px;
    //   }

    // }
  }
}
.page4 {
  box-sizing: border-box;
  width: 100%;
  height: 543px;
  background: url('../assets/img/home/homebg_page4@2x.png');
  background-size: cover;
  overflow: hidden;
  h3 {
    color: #ffffff;
  }
  .my_advantage {
    padding-left: 184px;
    margin-top: 74px;
    li {
      float: left;
      margin-right: 167px;
      width: 246px;
      text-align: center;
    }
    img {
      width: 92px;
      height: 92px;
    }
    .title {
      display: block;
      margin: 24px 0 16px;
      height: 41px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      line-height: 41px;
    }
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      span {
        display: block;
        height: 28px;
        line-height: 28px;
        opacity: 0.6;
      }
    }
  }
}
.page5 {
  width: 100%;
  height: 1166px;
  background: url('../assets/img/home/homebg_page5@2x.png');
  background-size: cover;
  box-sizing: border-box;
  overflow: hidden;
  .introduce {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 52px;
    width: 1180px;
    height: 212px;
    p {
      font-size: 14px;
      font-weight: 400;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #010c29;
      line-height: 36px;
    }
  }
  .smart_box {
    margin-top: 60px;
    // padding-left: 480px;
    width: 1180px;
    height: 90px;
    background: url('../assets/img/home/smartBox_bg.png') no-repeat;
    background-size: 100% 100%;
    img {
      margin-left: 40px;
      margin-top: 20px;
      // width: 1180px;
      height: 50px;
    }
  }
  .my_partner {
    margin-top: 19px;
    width: 1180px;
    // height: 725px;
    overflow: hidden;
    ul {
      width: 1220px;
      // height: 725px;
    }
    li {
      display: inline-block;
      position: relative;
      width: 220px;
      height: 90px;
      margin: 0 20px 19px 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.goAnalysis {
  position: relative;
  height: 446px;
  background-color: #fff;
  .leftB {
    margin-left: 130px;
    // margin-top: 121px;
    padding-top: 121px;
    width: 480px;
    .tit {
      // width: 160px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #010c29;
      line-height: 28px;
    }
    .lin {
      display: block;
      margin-top: 18px;
      width: 20px;
      height: 4px;
      background: #4671ff;
    }
    .txt {
      margin-top: 6px;
      height: 144px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #010c29;
      line-height: 36px;
    }
  }
  .rightB {
    position: absolute;
    top: 100px;
    right: 110px;
    width: 554px;
    height: 376px;
    img {
      width: 100%;
      height: 100%;
    }
    .learnMore {
      position: absolute;
      top: 256px;
      left: -88px;
      display: inline-block;
      padding-left: 24px;
      width: 141px;
      height: 36px;
      background: #4671ff;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.5s;
      .arrow {
        transition: all 0.5s;
        margin-left: 20px;
      }
    }
    .learnMore:hover {
      .arrow {
        margin-left: 25px;
      }
    }
  }
}
</style>
